import { defineStore } from "pinia";
import { User } from "@/types/user";
import { handleError } from "@/utils/error";
import WishlistService from "@/services/wishlist-service";
import { PaginatedResult } from "@/types/pagination";

const wishlistService = new WishlistService("wishlist");

export const useWishlistStore = defineStore("wishlist", {
  state: () => ({
    wishlisted: [] as User[],
    total: 0,
  }),
  actions: {
    async getWishlist(uri: string): Promise<PaginatedResult<User> | void> {
      try {
        const res: PaginatedResult<User> | void = await wishlistService.list(
          uri,
        );
        if (res && res.data) {
          this.wishlisted = res.data;
          this.total = res.total;
        }
      } catch (error) {
        throw handleError(error);
      }
    },
    async addToWishlist(proId: string): Promise<void> {
      try {
        await wishlistService.addToWishlist([proId]);
        await this.getWishlist("");
      } catch (error) {
        throw handleError(error);
      }
    },
    async removeFromWishlist(proId: string): Promise<void> {
      try {
        await wishlistService.removeFromWishlist([proId]);
        this.wishlisted = this.wishlisted.filter(
          (wishlisted) => wishlisted._id !== proId,
        );
      } catch (error) {
        throw handleError(error);
      }
    },
  },
});
