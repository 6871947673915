import { handleError } from "@/utils/error";
import { User } from "@/types/user";
import BaseService from "./base-service";
import { PaginatedResult } from "@/types/pagination";
import { getGAVariables } from "@/utils/helpers";

class WishlistService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async list(uri: string): Promise<PaginatedResult<User> | void> {
    try {
      return await this.get(`${this.getServiceContext}?${uri}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async addToWishlist(ids: string[]): Promise<User | void> {
    try {
      const analytics = await getGAVariables();
      return await this.post(`${this.getServiceContext}`, {
        proIds: ids,
        analytics,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async removeFromWishlist(ids: string[]): Promise<User | void> {
    try {
      return await this.delete(`${this.getServiceContext}`, { proIds: ids });
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default WishlistService;
